"use client";

import { useState, useEffect } from "react";

export function useUrlParams() {
  const [params, setParams] = useState<URLSearchParams>(
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search)
      : new URLSearchParams(),
  );

  useEffect(() => {
    const handleRouteChange = () => {
      setParams(new URLSearchParams(window.location.search));
    };

    window.addEventListener("popstate", handleRouteChange);

    // Observer les changements d'URL
    const originalPushState = history.pushState;
    history.pushState = function () {
      // @ts-ignore
      const result = originalPushState.apply(this, arguments);
      handleRouteChange();
      return result;
    };

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
      history.pushState = originalPushState;
    };
  }, []);

  return params;
}
