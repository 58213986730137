const translations = {
  fr: {
    // Header
    "nav.home": "Accueil",
    "nav.place": "Le lieu",
    "nav.services": "Services",
    "nav.rooms": "Nos chambres",
    "nav.reviews": "Avis",
    "nav.contact": "Contact",
    "cta.book": "Réserver",
    "cta.bookNow": "Réserver maintenant",

    // Hero Section
    "hero.title": "Les Chambres d'Anne",
    "hero.subtitle":
      "Chambres d'hôtes de charme dans une maison de maître de 1926, au cœur de la Seine-Maritime",

    // Intro Section
    "intro.title":
      "Venez passer un séjour unique dans notre chambre d'hôtes en Seine Maritime",
    "intro.paragraph1":
      "Anne vous accueil dans ce havre de paix, à la découverte de la région et de ses secrets.",
    "intro.paragraph2":
      "Que ce soit pour un séjour d'une nuit ou plus, vous pourrez profiter de l'ensemble des commodités de la maison.",
    "intro.paragraph3":
      "La Chambre d'Hôte est une maison de maître datant de 1926, construite par le grand-père d'Anne, en briques locales, sur le site d'une ancienne ferme, et entourée de vergers et de jardins potagers et floraux. La salle à manger et les chambres ont été restaurées pour conserver le charme et l'authenticité de la maison d'origine, tout en offrant des équipements modernes et confortables.",
    "intro.cta": "Découvrez nos chambres",

    // Place Section
    "place.title": "Notre lieu",
    "place.subtitle":
      "Découvrez l'élégance et le charme des Chambres d'Anne, un espace où le luxe rencontre l'authenticité",
    "place.caption1": "Le jardin fleuri et les bâtiments des Chambres d'Anne",
    "place.caption2":
      "Espace de jeux pour enfants entouré de fleurs dans notre jardin paisible",
    "place.caption3": "La belle bâtisse ornée de fleurs colorées",
    "place.caption4": "Notre jardin fleuri et le potager aux légumes de saison",
    "place.caption5": "Vue majestueuse de la bâtisse et son écrin de verdure",
    "place.caption6":
      "Panorama sur le jardin, le clocher du village et les maisons typiques normandes",
    "place.caption7": "Paysage bucolique des champs environnants",
    "place.caption8":
      "Notre poulailler et ses habitants : poules, coq et canards",
    "place.thumbnail": "Miniature",
    "place.previousPage": "Page précédente",
    "place.nextPage": "Page suivante",
    "place.pauseSlideshow": "Pause",
    "place.playSlideshow": "Lecture auto",
    "place.description":
      "Les Chambres d'Anne vous accueillent dans un cadre d'exception, où chaque détail a été pensé pour votre confort et votre bien-être. L'architecture élégante et l'aménagement soigné des espaces créent une atmosphère unique, alliant le charme d'antan au confort moderne.",

    // Services Section
    "services.title": "Les services proposés",
    "services.subtitle":
      "Découvrez tous les services proposés par notre chambre d'hôtes",
    "services.wifi.title": "WiFi",
    "services.wifi.description":
      "Profitez d'une connexion WiFi gratuite dans tout l'établissement pour rester connecté durant votre séjour.",
    "services.parking.title": "Parking privé",
    "services.parking.description":
      "Garez votre véhicule en toute sécurité avec notre parking privé gratuit, à proximité immédiate de votre hébergement.",
    "services.outdoor.title": "Espace extérieur",
    "services.outdoor.description":
      "Détendez-vous dans notre espace extérieur aménagé avec jardin et terrasse pour des moments de convivialité.",
    "services.breakfast.title": "Petit déjeuner inclus",
    "services.breakfast.description":
      "Commencez votre journée avec un délicieux petit déjeuner inclus, préparé avec des produits de saison et faits maison.",
    "services.linen.title": "Linge de maison",
    "services.linen.description":
      "Tout le linge de maison est fourni pour votre confort, incluant draps, serviettes et linge de lit.",
    "services.nonsmoking.title": "Non-fumeur",
    "services.nonsmoking.description":
      "Notre établissement est entièrement non-fumeur, garantissant un environnement propre et sain.",

    // Extra Section
    "extra.title":
      "Savourez une authentique expérience culinaire pendant votre séjour",
    "extra.paragraph1":
      "Vous êtes assurés d'un accueil chaleureux de la part d'Anne, qui utilise sa passion pour la cuisine et ses expériences à l'école hôtelière et dans les restaurants locaux pour proposer une table d'hôte de qualité.",
    "extra.paragraph2":
      "Pour 20€ par personne, dégustez des plats authentiques créés avec les produits du jardin et du terroir local : fromages de Neufchâtel en Bray, cidre et Calvados de producteurs locaux, accompagnés de yaourts, gâteaux, confitures et conserves faits maison.",
    "extra.cta": "Réserver votre séjour",
    "extra.imageAlt": "Repas traditionnels servis dans notre chambre d'hôtes",

    // Reservation Section
    "reservation.title": "Réservation",
    "reservation.subtitle":
      "Réservez votre séjour aux Chambres d'Anne et profitez d'une expérience inoubliable",
    "reservation.availability": "Disponibilités et tarifs",
    "reservation.checkAvailability":
      "Consultez nos disponibilités et réservez directement en ligne",
    "reservation.contact":
      "Pour toute demande spécifique ou assistance, n'hésitez pas à nous contacter directement",
    "reservation.bestRates":
      "Les Chambres d'Anne vous garantissent les meilleurs tarifs en réservant directement sur notre site. Nous vous accueillons toute l'année pour des séjours inoubliables dans notre établissement de charme.",

    // Reviews Section
    "reviews.title": "Témoignages",
    "reviews.subtitle":
      "Ce que nos hôtes disent de leur expérience aux Chambres d'Anne",
    "reviews.previousReview": "Témoignage précédent",
    "reviews.nextReview": "Témoignage suivant",
    "reviews.viewReview": "Voir le témoignage",
    "reviews.leaveReview": "Laisser un avis",
    "reviews.viewAllReviews": "Voir tous les avis",

    // Footer
    "footer.brandName": "Les Chambres d'Anne",
    "footer.tagline":
      "Maison d'hôtes de charme au cœur de la campagne française",
    "footer.navigation": "Navigation",
    "footer.address": "Adresse",
    "footer.viewOnGoogleMaps": "Voir sur Google Maps",
    "footer.developedBy": "Site développé par",
    "footer.cookies": "Cookies",
    "footer.legalNotice": "Mentions légales",

    // Breadcrumbs
    "breadcrumb.backToRooms": "Retour à la liste des chambres",
    "breadcrumb.backToHome": "Retour à l'accueil",

    // Legal Notice Page
    "legal.title": "Mentions légales",
    "legal.section1.title": "Mentions Légales",
    "legal.section2.title": "Protection des Données (RGPD)",
    "legal.section3.title": "Propriété Intellectuelle",
    "legal.section4.title": "Conditions Générales d'Utilisation (CGU)",
    "legal.section5.title": "Acceptation",

    // Rooms Section
    "rooms.title": "Nos chambres d'exception",
    "rooms.subtitle":
      "Découvrez l'élégance et le confort de nos chambres de charme. Chaque chambre a été décorée avec soin pour vous offrir une expérience unique.",
    "rooms.person": "personne",
    "rooms.persons": "personnes",
    "rooms.to": "à",
    "rooms.startingFrom": "À partir de",
    "rooms.discover": "Découvrir cette chambre",
    "rooms.bookStay": "RÉSERVER VOTRE SÉJOUR",

    // Room Detail Page
    "room.view": "Vue",
    "room.bed.single": "1 lit simple",
    "room.bed.double": "1 lit double",
    "room.bed.multiple": "Plusieurs lits",
    "room.amenities": "Équipements et services",
    "room.amenity.wifi": "WiFi gratuit",
    "room.amenity.bathroom": "Salle d'eau privative",
    "room.amenity.toilet": "WC privatifs",
    "room.amenity.breakfast": "Petit-déjeuner inclus",
    "room.amenity.bedding": "Lits préparés avec soin pour votre arrivée",
    "room.amenity.towels": "Serviettes fournies",
    "room.rates": "Tarifs",
    "room.taxes": "Taxes et suppléments",
    "room.bookThis": "RÉSERVER CETTE CHAMBRE",
    "room.cancellation": "Annulation gratuite jusqu'à 48h avant l'arrivée",
    "room.discover": "Découvrez nos autres chambres",

    // Room Descriptions
    "room.camelia.short":
      "Chambre double avec salle d'eau et WC privatifs, située au rez-de-chaussée.",
    "room.camelia.long":
      "Située au rez-de-chaussée, cette chambre comprend un lit 2 personnes (140x190cm), une salle d'eau avec wc privatifs. Cette chambre vous enchantera par sa décoration et la qualité de sa literie.",
    "room.bois-dore.short":
      "Chambre double avec salle d'eau et WC privatifs, située à l'étage.",
    "room.bois-dore.long":
      "Située à l'étage, cette chambre comprend un lit 2 personnes (160x190cm), une salle d'eau avec wc privatifs. Cette chambre vous enchantera par sa décoration et la qualité de sa literie.",
    "room.orchidee.short":
      "Chambre double avec salle d'eau et WC privatifs, située à l'étage, avec possibilité d'ajouter un lit.",
    "room.orchidee.long":
      "Située à l'étage, cette chambre comprend un lit 2 personnes (160x190cm), un lit 1 personne (90x190cm), une salle d'eau avec wc privatifs. Cette chambre vous enchantera par sa décoration et la qualité de sa literie.",
    "room.pivoine.short":
      "Chambre familiale avec salle d'eau et WC privatifs, située à l'étage.",
    "room.pivoine.long":
      "Située à l'étage, cette chambre familiale comprend un lit 2 personnes (160x200cm), 2 lits 1 personne (90x200cm), une salle d'eau avec wc séparés privatifs. Cette chambre vous enchantera par sa décoration et la qualité de sa literie. Idéale pour une famille avec 2 enfants, elle réserve de la place pour évoluer. Avec ses 40m², elle offre un espace confortable pour toute la famille.",

    // Additional Fees
    "fee.taxe-sejour": "Montant de la taxe de séjour par adulte (+ de 18 ans)",
    "fee.assiette-gourmande":
      "Assiette Gourmande (inclus fromage, dessert maison et boisson comprise)",
    "fee.personne-supplementaire": "Personne supplémentaire",
    "fee.lit-enfant": "Tarif lit enfant (2 à 12 ans)",

    // Cookies Page
    "cookies.title": "Gestion des Cookies",
    "cookies.section1.title": "Notre Politique de Cookies",
    "cookies.section2.title": "Cookies Essentiels",
    "cookies.section3.title": "Aucun Tracking",
    "cookies.section4.title": "Vos Droits",
    "cookies.section5.title": "Questions ?",

    // Reservation Page
    "reservation.page.subtitle":
      "Réservez votre séjour aux Chambres d'Anne et profitez d'une expérience inoubliable dans notre établissement de charme",
    "reservation.page.availability": "Disponibilités et tarifs",
    "reservation.page.checkAvailability":
      "Consultez nos disponibilités et réservez directement en ligne. Nous vous garantissons les meilleurs tarifs en réservant sur notre site.",
    "reservation.page.importantInfo": "Informations importantes",
    "reservation.page.checkIn": "Arrivée à partir de 15h, départ avant 11h",
    "reservation.page.breakfast": "Petit-déjeuner inclus dans tous nos tarifs",
    "reservation.page.pets":
      "Animaux acceptés sur demande (supplément possible)",
    "reservation.page.parking": "Parking gratuit sur place",
    "reservation.page.wifi": "Wifi gratuit dans tout l'établissement",
    "reservation.page.contact":
      "Pour toute demande spécifique ou assistance avec votre réservation,",
    "reservation.page.contactUs": "contactez-nous directement",
  },
  en: {
    // Header
    "nav.home": "Home",
    "nav.place": "The Place",
    "nav.services": "Services",
    "nav.rooms": "Our Rooms",
    "nav.reviews": "Reviews",
    "nav.contact": "Contact",
    "cta.book": "Book",
    "cta.bookNow": "Book Now",

    // Hero Section
    "hero.title": "Anne's Rooms",
    "hero.subtitle":
      "Charming guest rooms in a 1926 mansion, in the heart of Seine-Maritime",

    // Intro Section
    "intro.title":
      "Come and enjoy a unique stay in our guest house in Seine Maritime",
    "intro.paragraph1":
      "Anne welcomes you to this haven of peace, to discover the region and its secrets.",
    "intro.paragraph2":
      "Whether for a stay of one night or more, you will be able to enjoy all the amenities of the house.",
    "intro.paragraph3":
      "The Guest House is a mansion dating from 1926, built by Anne's grandfather, using local bricks, on the site of an old farm, and surrounded by orchards and vegetable and flower gardens. The dining room and bedrooms have been restored to preserve the charm and authenticity of the original house, while offering modern and comfortable amenities.",
    "intro.cta": "Discover our rooms",

    // Place Section
    "place.title": "Our Place",
    "place.subtitle":
      "Discover the elegance and charm of Anne's Rooms, a space where luxury meets authenticity",
    "place.caption1": "The flowery garden and buildings of Anne's Rooms",
    "place.caption2":
      "Children's play area surrounded by flowers in our peaceful garden",
    "place.caption3": "The beautiful mansion adorned with colorful flowers",
    "place.caption4":
      "Our flowering garden and vegetable patch with seasonal produce",
    "place.caption5": "Majestic view of the mansion and its lush surroundings",
    "place.caption6":
      "Panoramic view of the garden, village church spire and typical Norman houses",
    "place.caption7": "Bucolic landscape of the surrounding fields",
    "place.caption8":
      "Our chicken coop and its inhabitants: hens, rooster, and ducks",
    "place.thumbnail": "Thumbnail",
    "place.previousPage": "Previous page",
    "place.nextPage": "Next page",
    "place.pauseSlideshow": "Pause",
    "place.playSlideshow": "Auto play",
    "place.description":
      "Anne's Rooms welcomes you in an exceptional setting, where every detail has been designed for your comfort and well-being. The elegant architecture and careful layout of the spaces create a unique atmosphere, combining old-world charm with modern comfort.",

    // Services Section
    "services.title": "Our Services",
    "services.subtitle": "Discover all the services offered by our guest house",
    "services.wifi.title": "WiFi",
    "services.wifi.description":
      "Enjoy free WiFi connection throughout the establishment to stay connected during your stay.",
    "services.parking.title": "Private Parking",
    "services.parking.description":
      "Park your vehicle safely with our free private parking, in the immediate vicinity of your accommodation.",
    "services.outdoor.title": "Outdoor Space",
    "services.outdoor.description":
      "Relax in our outdoor space with garden and terrace for moments of conviviality.",
    "services.breakfast.title": "Breakfast included",
    "services.breakfast.description":
      "Start your day with a delicious included breakfast, prepared with seasonal and homemade products.",
    "services.linen.title": "Household Linen",
    "services.linen.description":
      "All household linen is provided for your comfort, including sheets, towels and bed linen.",
    "services.nonsmoking.title": "Non-smoking",
    "services.nonsmoking.description":
      "Our establishment is entirely non-smoking, guaranteeing a clean and healthy environment.",

    // Reservation Section
    "reservation.title": "Booking",
    "reservation.subtitle":
      "Book your stay at Anne's Rooms and enjoy an unforgettable experience",
    "reservation.availability": "Availability and rates",
    "reservation.checkAvailability":
      "Check our availability and book directly online",
    "reservation.contact":
      "For any specific request or assistance, please do not hesitate to contact us directly",
    "reservation.bestRates":
      "Anne's Rooms guarantees you the best rates by booking directly on our website. We welcome you all year round for unforgettable stays in our charming establishment.",

    // Reviews Section
    "reviews.title": "Testimonials",
    "reviews.subtitle":
      "What our guests say about their experience at Anne's Rooms",
    "reviews.previousReview": "Previous review",
    "reviews.nextReview": "Next review",
    "reviews.viewReview": "View review",
    "reviews.leaveReview": "Leave a review",
    "reviews.viewAllReviews": "View all reviews",

    // Footer
    "footer.brandName": "Anne's Rooms",
    "footer.tagline":
      "Charming guest house in the heart of the French countryside",
    "footer.navigation": "Navigation",
    "footer.address": "Address",
    "footer.viewOnGoogleMaps": "View on Google Maps",
    "footer.developedBy": "Website developed by",
    "footer.cookies": "Cookies",
    "footer.legalNotice": "Legal Notice",

    // Breadcrumbs
    "breadcrumb.backToRooms": "Back to rooms list",
    "breadcrumb.backToHome": "Back to home",

    // Legal Notice Page
    "legal.title": "Legal Notice",
    "legal.section1.title": "Legal Notice",
    "legal.section2.title": "Data Protection (GDPR)",
    "legal.section3.title": "Intellectual Property",
    "legal.section4.title": "Terms of Use",
    "legal.section5.title": "Acceptance",

    // Extra Section
    "extra.title": "Enjoy an authentic culinary experience during your stay",
    "extra.paragraph1":
      "You are assured of a warm welcome from Anne, who uses her passion for cooking and her experiences at culinary school and in local restaurants to offer a high-quality table d'hôte.",
    "extra.paragraph2":
      "For €20 per person, taste authentic dishes created with products from the garden and local terroir: Neufchâtel en Bray cheeses, cider and Calvados from local producers, accompanied by homemade yogurts, cakes, jams and preserves.",
    "extra.cta": "Book your stay",
    "extra.imageAlt": "Traditional meals served in our guest house",

    // Rooms Section
    "rooms.title": "Our Exceptional Rooms",
    "rooms.subtitle":
      "Discover the elegance and comfort of our charming rooms. Each room has been carefully decorated to offer you a unique experience.",
    "rooms.person": "person",
    "rooms.persons": "people",
    "rooms.to": "to",
    "rooms.startingFrom": "Starting from",
    "rooms.discover": "Discover this room",
    "rooms.bookStay": "BOOK YOUR STAY",

    // Room Detail Page
    "room.view": "View",
    "room.bed.single": "1 single bed",
    "room.bed.double": "1 double bed",
    "room.bed.multiple": "Multiple beds",
    "room.amenities": "Amenities and services",
    "room.amenity.wifi": "Free WiFi",
    "room.amenity.bathroom": "Private bathroom",
    "room.amenity.toilet": "Private toilet",
    "room.amenity.breakfast": "Breakfast included",
    "room.amenity.bedding": "Beds elegantly prepared for your arrival",
    "room.amenity.towels": "Towels provided",
    "room.rates": "Rates",
    "room.taxes": "Taxes and supplements",
    "room.bookThis": "BOOK THIS ROOM",
    "room.cancellation": "Free cancellation up to 48 hours before arrival",
    "room.discover": "Discover our other rooms",

    // Room Descriptions
    "room.camelia.short":
      "Double room with private bathroom and toilet, located on the ground floor.",
    "room.camelia.long":
      "Located on the ground floor, this room includes a double bed (140x190cm), a bathroom with private toilet. This room will enchant you with its decoration and the quality of its bedding.",
    "room.bois-dore.short":
      "Double room with private bathroom and toilet, located on the first floor.",
    "room.bois-dore.long":
      "Located on the first floor, this room includes a double bed (160x190cm), a bathroom with private toilet. This room will enchant you with its decoration and the quality of its bedding.",
    "room.orchidee.short":
      "Double room with private bathroom and toilet, located on the first floor, with possibility to add an extra bed.",
    "room.orchidee.long":
      "Located on the first floor, this room includes a double bed (160x190cm), a single bed (90x190cm), a bathroom with private toilet. This room will enchant you with its decoration and the quality of its bedding.",
    "room.pivoine.short":
      "Family room with private bathroom and toilet, located on the first floor.",
    "room.pivoine.long":
      "Located on the first floor, this family room includes a double bed (160x200cm), 2 single beds (90x200cm), a bathroom with separate private toilet. This room will enchant you with its decoration and the quality of its bedding. Ideal for a family with 2 children, it has plenty of space to move around. With its 40m², it offers a comfortable space for the whole family.",

    // Additional Fees
    "fee.taxe-sejour": "Tourist tax amount per adult (over 18 years old)",
    "fee.assiette-gourmande":
      "Gourmet plate (includes cheese, homemade dessert and drink)",
    "fee.personne-supplementaire": "Additional person",
    "fee.lit-enfant": "Child bed rate (2 to 12 years old)",

    // Cookies Page
    "cookies.title": "Cookie Management",
    "cookies.section1.title": "Our Cookie Policy",
    "cookies.section2.title": "Essential Cookies",
    "cookies.section3.title": "No Tracking",
    "cookies.section4.title": "Your Rights",
    "cookies.section5.title": "Questions?",

    // Reservation Page
    "reservation.page.subtitle":
      "Book your stay at Anne's Rooms and enjoy an unforgettable experience in our charming establishment",
    "reservation.page.availability": "Availability and rates",
    "reservation.page.checkAvailability":
      "Check our availability and book directly online. We guarantee you the best rates by booking on our website.",
    "reservation.page.importantInfo": "Important information",
    "reservation.page.checkIn": "Check-in from 3pm, check-out before 11am",
    "reservation.page.breakfast": "Breakfast included in all our rates",
    "reservation.page.pets": "Pets accepted on request (supplement may apply)",
    "reservation.page.parking": "Free on-site parking",
    "reservation.page.wifi": "Free WiFi throughout the establishment",
    "reservation.page.contact":
      "For any specific request or assistance with your reservation,",
    "reservation.page.contactUs": "contact us directly",
  },
};

export default translations;
