export const defaultLocale = "fr";
export const locales = ["fr", "en"] as const;
export type Locale = (typeof locales)[number];

export const getLocaleFromPathname = (pathname: string): Locale => {
  // Vérifier d'abord les paramètres d'URL si disponibles
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get("lang");
    if (langParam && locales.includes(langParam as Locale)) {
      return langParam as Locale;
    }
  }

  // Sinon, vérifier le chemin
  const segments = pathname.split("/");
  const localeSegment = segments[1];

  if (locales.includes(localeSegment as Locale)) {
    return localeSegment as Locale;
  }

  return defaultLocale;
};

export const removeLocaleFromPathname = (pathname: string): string => {
  const segments = pathname.split("/");
  const localeSegment = segments[1];

  if (locales.includes(localeSegment as Locale)) {
    return "/" + segments.slice(2).join("/");
  }

  return pathname;
};

export const addLocaleToPathname = (
  pathname: string,
  locale: Locale,
): string => {
  const cleanPath = removeLocaleFromPathname(pathname);
  if (locale === defaultLocale) {
    return cleanPath;
  }
  return `/${locale}${cleanPath}`;
};
