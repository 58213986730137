import { Locale, defaultLocale } from "@/i18n/settings";

/**
 * Ajoute le paramètre de langue aux liens si nécessaire
 * @param href Le lien à localiser
 * @param locale La langue actuelle
 * @returns Le lien localisé
 */
export const getLocalizedHref = (href: string, locale: Locale): string => {
  if (locale === defaultLocale) return href;

  // Si c'est un lien avec un hash, on préserve le hash
  if (href.includes("#")) {
    const [path, hash] = href.split("#");
    // Vérifier si le chemin contient déjà des paramètres de requête
    const separator = path.includes("?") ? "&" : "?";
    return `${path}${separator}lang=${locale}#${hash}`;
  }

  // Vérifier si le lien contient déjà des paramètres de requête
  const separator = href.includes("?") ? "&" : "?";
  return `${href}${separator}lang=${locale}`;
};
