"use client";

import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { usePathname, useRouter } from "next/navigation";
import {
  Locale,
  defaultLocale,
  getLocaleFromPathname,
  addLocaleToPathname,
  locales,
} from "./settings";
import translations from "./translations";
import { useUrlParams } from "@/hooks/useUrlParams";

type TranslationsType = typeof translations.fr;

interface I18nContextType {
  locale: Locale;
  t: (key: keyof TranslationsType) => string;
  changeLocale: (newLocale: Locale) => void;
}

export const I18nContext = createContext<I18nContextType | null>(null);

export const I18nProvider = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  const router = useRouter();
  const urlParams = useUrlParams();
  const [locale, setLocale] = useState<Locale>(defaultLocale);

  useEffect(() => {
    const langParam = urlParams.get("lang");

    if (langParam && locales.includes(langParam as Locale)) {
      setLocale(langParam as Locale);
    } else {
      setLocale(getLocaleFromPathname(pathname));
    }
  }, [pathname, urlParams]);

  const t = (key: keyof TranslationsType): string => {
    return translations[locale][key] || translations[defaultLocale][key] || key;
  };

  const changeLocale = (newLocale: Locale) => {
    if (newLocale === defaultLocale) {
      router.push("/");
    } else {
      const url = new URL(window.location.href);
      url.searchParams.set("lang", newLocale);
      router.push(url.pathname + url.search);
    }
  };

  return (
    <I18nContext.Provider value={{ locale, t, changeLocale }}>
      {children}
    </I18nContext.Provider>
  );
};

export const useI18n = () => {
  const context = useContext(I18nContext);
  if (!context) {
    throw new Error("useI18n must be used within an I18nProvider");
  }
  return context;
};
